.box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  @media (max-width: 800px) {
    margin-top: 2rem;
  }
}

.imageBox {
  width: 100%;
  padding-top: 10rem;
  padding-bottom: 10rem;
  background-image: url("../../assets/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 6.25rem;
    font-weight: 800;
    color: white;
    text-align: center;
    width: 80%;

    @media (max-width: 1300px) {
      margin-left: 3rem;
      margin-right: 3rem;
    }

    @media (max-width: 1300px) {
      font-size: 4rem;
    }

    @media (max-width: 600px) {
      font-size: 2rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  @media (max-width: 1300px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.leftBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 1300px) {
    width: 60%;
  }

  @media (max-width: 800px) {
    width: 90%;
  }
}

.rightBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1300px) {
    width: 40%;
  }

  @media (max-width: 800px) {
    width: 90%;
    margin-top: 2rem;
  }
}

.leftContainer {
  width: 65%;
  margin-right: 3rem;

  @media (max-width: 1500px) {
    width: 80%;
    margin-right: 2rem;
  }

  @media (max-width: 1050px) {
    width: 90%;
  }

  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0rem;
  }
}

.heading {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--GRAY-COLOR);

  @media (max-width: 1050px) {
    font-size: 1rem;
  }

  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.subHeading {
  font-size: 3rem;
  font-weight: bold;
  margin-top: 1rem;

  @media (max-width: 1050px) {
    font-size: 2.2rem;
  }

  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.subText {
  font-size: 1.875rem;
  font-weight: 600;
  margin-top: 1rem;

  @media (max-width: 1050px) {
    font-size: 1.3rem;
  }

  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.image {
  width: 100%;
  height: 34.375rem;
  object-fit: cover;
  margin-right: -1rem;
  border-radius: 1rem;

  @media (max-width: 800px) {
    margin-right: 0rem;
  }
}

.button {
  background-color: var(--PRIMARY-COLOR);
  margin-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;

  p {
    @media (max-width: 1050px) {
      font-size: 1.3rem !important;
    }
  }
}

.group {
  width: 20rem;
  object-fit: contain;
  margin-top: -2.5rem;

  @media (max-width: 1300px) {
    width: 15rem;
  }
}
