.box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
  flex-direction: column;
  margin-bottom: 1rem;
}

.container {
  width: 100%;
  margin-top: 3rem;
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.imageBox {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  margin-left: 1rem;

  @media (max-width: 1200px) {
    margin-top: 0rem;
  }

  @media (max-width: 800px) {
    width: 90%;
    margin-top: 3rem;
    margin-left: 0rem;
  }
}

.image {
  width: 50%;
  object-fit: contain;
  margin-bottom: 1rem;

  @media (max-width: 800px) {
    width: 8rem;
  }
}

.text {
  font-size: 1.125rem;
  text-align: center;
  margin-top: 2rem;

  @media (max-width: 1200px) {
    font-size: 1rem;
    margin-top: 1rem;
  }

  @media (max-width: 1000px) {
    font-size: 0.9rem;
  }
}

.backgroundBox {
  width: 100%;
  padding-top: 5rem;
  background-color: var(--GRAY-LIGHT);
  background-image: url("../../assets/reactangleTilt1.svg");
  background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.twoSideBox {
  width: 100%;
  margin-top: 5rem;
}

.rightBox {
  width: 60%;

  @media (max-width: 800px) {
    width: 80%;
  }
}

.rightHeading {
  font-size: 1.875rem;
  font-weight: bold;
  color: var(--PRIMARY-COLOR);
}

.heading {
  font-size: 3.75rem;
  font-weight: bolder;
  color: white;
  margin-top: 1rem;

  @media (max-width: 1300px) {
    font-size: 2.5rem;
  }

  @media (max-width: 1000px) {
    font-size: 2.2rem;
  }

  @media (max-width: 600px) {
    font-size: 1.8rem;
  }
}

.customerHeading {
  font-size: 3.75rem;
  font-weight: bolder;
  color: var(--BLUE-COLOR);
  margin-top: 1rem;

  @media (max-width: 1300px) {
    font-size: 2.5rem;
  }

  @media (max-width: 1000px) {
    font-size: 2.2rem;
  }

  @media (max-width: 600px) {
    font-size: 1.8rem;
  }
}

.featureBoxText {
  margin-top: 1rem;
  font-size: 1.125rem;
  color: white;

  @media (max-width: 1300px) {
    font-size: 0.9rem;
  }

  @media (max-width: 1000px) {
    font-size: 1.125rem;
  }

  @media (max-width: 600px) {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
}

.customerBox {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 15rem;
}

.customerContainer {
  background-color: white;
  border-top-left-radius: 1.25rem;
  width: 90%;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-image: url("../../assets/grayWorld.png");
  background-position-x: right;
  background-position-y: top;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 600px) {
    width: 95%;
    background-position-x: right;
    background-position-y: center;
  }
}

.underline {
  width: 10rem;
  height: 0.5rem;
  border-radius: 100rem;
  background-color: var(--PRIMARY-COLOR);
  margin-top: 1rem;
}

.customerSubHeading {
  font-size: 1.5rem;
  color: black;
  font-weight: bold;
  width: 50%;
  margin-top: 3rem;
  text-align: center;

  @media (max-width: 800px) {
    width: 80%;
  }
}

.customersListBox {
  width: 80%;

  @media (max-width: 1200px) {
    width: 90%;
  }
}

.happyBox {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
    // align-items: center;
  }
}

.happyImage {
  width: 30%;
  display: flex;

  @media (max-width: 800px) {
    width: 80%;
    margin-top: -10%;

    &:nth-child(1) {
      margin-top: 0;
    }

    &:nth-child(even) {
      align-self: flex-end;
    }
  }
}
