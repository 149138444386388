.box {
  width: 100%;
  display: flex;
  margin-top: 10rem;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.leftBox {
  width: 50%;
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  z-index: 1;
  margin-top: -5rem;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 1000px) {
    width: 100%;
    z-index: 0;
  }
}

.rightBox {
  width: 60%;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  background-color: var(--BLUE-COLOR);
  margin-left: -10%;
  margin-bottom: -5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 90%;
    border-radius: 1.25rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin-bottom: 0rem;
    margin-top: -5rem;
    z-index: 1;
    margin-left: 0;
  }
}
