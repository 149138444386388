.box {
  width: 80%;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  background-color: white;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  @media (max-width: 600px) {
    padding: 1rem;
  }
}

.rightHeading {
  color: var(--BLUE-COLOR);
  font-weight: bold;
  font-size: 3.125rem;

  @media (max-width: 1200px) {
    font-size: 2.8rem;
  }

  @media (max-width: 600px) {
    font-size: 2rem;
    margin-top: 2rem;
  }
}

.leftSide {
  width: 50%;

  @media (max-width: 1000px) {
    width: 90%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
}

.leftBox {
  width: 70%;

  @media (max-width: 1000px) {
    width: 100%;
  }
}

.rightSide {
  width: 50%;

  img {
    width: 100%;
  }

  @media (max-width: 1000px) {
    width: 90%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
}

.featureBoxText {
  margin-top: 1rem;
  font-size: 1.125rem;

  @media (max-width: 1300px) {
    font-size: 0.9rem;
  }

  @media (max-width: 1000px) {
    font-size: 1.125rem;
  }

  @media (max-width: 600px) {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
}

.bottomBox {
  position: absolute;
  margin-top: 15rem;
  margin-left: 5rem;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50%;
  }

  @media (max-width: 1000px) {
    display: none;
  }
}

.pointsBox {
  margin-top: 2rem;

  li {
    color: black;
    font-weight: 600;
    font-size: 1.125rem;

    @media (max-width: 1300px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1000px) {
      font-size: 1.125rem;
    }

    @media (max-width: 600px) {
      font-size: 1rem;
      margin-top: 0.5rem;
    }
  }
}
