:root {
  --FONT-FAMILY: "Montserrat";
}

html {
  scroll-behavior: smooth;
}

p {
  font-family: var(--FONT-FAMILY);
  margin: 0;
  padding: 0;
}

span {
  font-family: var(--FONT-FAMILY);
  margin: 0;
  padding: 0;
}

li {
  font-family: var(--FONT-FAMILY);
  margin: 0;
  padding: 0;
}

input:focus {
  outline: none;
}
