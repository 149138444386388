.box {
  width: 100%;
  background-color: var(--PRIMARY-COLOR);
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
  }
}

.leftSide {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  @media (max-width: 800px) {
    margin-bottom: 3rem;
  }

  @media (max-width: 800px) {
    margin-bottom: 3rem;
    margin-top: 1rem;
  }
}

.menuBox {
  margin-left: 1rem;
  &:nth-child(1) {
    margin-left: 0rem;
  }

  p {
    font-size: 1.5rem;
    font-weight: bolder;
    color: white;
    text-transform: uppercase;
    cursor: pointer;

    @media (max-width: 1150px) {
      font-size: 1.2rem;
    }

    @media (max-width: 800px) {
      text-align: center;
    }
  }

  @media (max-width: 800px) {
    margin-left: 0rem;
    margin-top: 1rem;
  }
}

.button {
  background-color: var(--BLUE-COLOR);
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  margin-bottom: 3rem;

  p {
    color: black;
  }
}
