.box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container {
  width: 100%;
  margin-top: 3rem;
}

.row {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;

  @media (max-width: 1200px) {
    grid-template-columns: 33% 33% 33%;
  }
}

.textBox {
  padding-top: 3rem;
  padding-bottom: 3rem;
  border: 0.3px solid var(--BORDER-COLOR);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;

  img {
    @media (max-width: 800px) {
      max-width: 90%;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  &:hover {
    background-color: var(--GRAY-LIGHT);
  }

  @media (max-width: 800px) {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
}

.textBoxnoHover {
  padding-top: 3rem;
  padding-bottom: 3rem;
  border: 0.3px solid var(--BORDER-COLOR);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}

.heading {
  font-size: 2rem;
  font-weight: bold;
  color: black;
  text-align: center;

  @media (max-width: 1200px) {
    font-size: 1.5rem;
  }
  @media (max-width: 1000px) {
    font-size: 1.2rem;
  }

  @media (max-width: 600px) {
    text-align: center;
  }
}

.arrow {
  width: 1.5rem;
  object-fit: contain;
  margin-top: 2rem;
}
