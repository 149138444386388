.box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading {
  font-size: 3.75rem;
  font-weight: bolder;
  color: var(--BLUE-COLOR);
  text-align: center;

  @media (max-width: 600px) {
    font-size: 2.25rem;
  }
}

.underline {
  width: 10rem;
  height: 0.5rem;
  border-radius: 100rem;
  background-color: var(--PRIMARY-COLOR);
  margin-top: 1rem;
}

.subHeading {
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
  width: 50%;
  text-align: center;
  margin-top: 2rem;

  @media (max-width: 800px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
}

.selectorBox {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;

  @media (max-width: 800px) {
    width: 95%;
  }
}

.boxUnselected {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid var(--BORDER-COLOR);
  border-radius: 0.625rem;
  flex-direction: column;
  cursor: pointer;
  height: 10rem;

  img {
    @media (max-width: 1100px) {
      width: 3rem;
    }

    @media (max-width: 500px) {
      width: 1.5rem;
    }
  }

  p {
    color: black;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    margin-top: 1rem;

    @media (max-width: 1100px) {
      font-size: 0.9rem;
    }

    @media (max-width: 650px) {
      font-size: 0.8rem;
    }

    @media (max-width: 500px) {
      font-size: 0.6rem;
    }
  }

  @media (max-width: 1100px) {
    height: 8rem;
  }

  @media (max-width: 1100px) {
    height: 6rem;
  }

  @media (max-width: 500px) {
    height: 4rem;
  }
}

.boxSelected {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--PRIMARY-COLOR);
  flex-direction: column;
  border-radius: 0.625rem;
  cursor: pointer;
  height: 10rem;

  img {
    @media (max-width: 1100px) {
      width: 3rem;
    }

    @media (max-width: 500px) {
      width: 1.5rem;
    }
  }

  p {
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    margin-top: 1rem;

    @media (max-width: 1100px) {
      font-size: 0.9rem;
    }

    @media (max-width: 650px) {
      font-size: 0.8rem;
    }

    @media (max-width: 500px) {
      font-size: 0.6rem;
    }
  }

  @media (max-width: 1100px) {
    height: 8rem;
  }

  @media (max-width: 1100px) {
    height: 6rem;
  }

  @media (max-width: 500px) {
    height: 4rem;
  }
}

.polygon {
  width: 2rem;
  height: 1rem;
  z-index: 1;
  margin-top: -0.0625rem;
}

.polygonUnselcted {
  width: 2rem;
  height: 1rem;
  z-index: 1;
  opacity: 0;
  margin-top: -0.0625rem;
}

.selectBox {
  width: 20%;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 850px) {
    width: 22%;
  }
}

.component {
  margin-top: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
