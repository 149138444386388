.box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--GRAY-LIGHT);
  backdrop-filter: blur(10px);
  padding-top: 5rem;
}

.containerBox {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: space-between;
  }
}

.container {
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(279.56deg, #eeeeee 0%, #ffffff 100%), #e4e6e7;
  justify-content: center;
}

.textBox {
  width: 35%;

  @media (max-width: 1200px) {
    width: 40%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
}

.formBox {
  width: 35%;
  background-color: white;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  border-radius: 1.25rem;

  @media (max-width: 1200px) {
    width: 40%;
  }

  @media (max-width: 800px) {
    width: 80%;
    margin-top: 3rem;
    padding: 2rem;
  }
}

.heading {
  font-size: 2.8125rem;
  font-weight: bold;
  color: black;

  @media (max-width: 1200px) {
    font-size: 2rem;
  }

  @media (max-width: 1000px) {
    font-size: 1.5rem;
  }

  @media (max-width: 800px) {
    text-align: center;
  }
}

.subHeading {
  font-size: 1.675rem;
  color: black;
  margin-top: 1rem;

  @media (max-width: 1200px) {
    font-size: 1.3rem;
  }

  @media (max-width: 1000px) {
    font-size: 1rem;
  }

  @media (max-width: 800px) {
    text-align: center;
  }
}

.formHeading {
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
}

.formButton {
  border-radius: 0.625rem;
  background-color: var(--PRIMARY-COLOR);
  margin-top: 1rem;

  p {
    color: white;
    font-weight: 600;
  }
}
