:root {
  --header-width: 90%;
}

.box {
  width: var(--header-width);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  z-index: 99;
  padding-bottom: 2rem;

  @media (max-width: 1300px) {
    width: 95%;
  }
}

.leftSide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--FONT-FAMILY);
}

.rightSide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--FONT-FAMILY);
  margin-top: 0.625rem;
  margin-left: 3rem;

  @media (max-width: 1300px) {
    margin-left: 1rem;
  }

  @media (max-width: 1150px) {
    margin-left: 0rem;
  }

  @media (max-width: 1000px) {
    display: none;
  }
}

.menuBoxWithBorder {
  // height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  margin-left: 1rem;

  p {
    font-size: 1.5rem;
    font-weight: bolder;
    color: black;
    text-transform: uppercase;
    cursor: pointer;

    @media (max-width: 1400px) {
      font-size: 1.2rem;
    }

    @media (max-width: 1150px) {
      font-size: 1rem;
    }
  }
}

.dropDownMenuIcon {
  display: none;

  @media (max-width: 1000px) {
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.menuIcon {
  width: 2rem;
  height: 2rem;
}

.menuBox {
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  margin-left: 1rem;

  p {
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--MENU-COLOR);
    text-transform: uppercase;
    cursor: pointer;

    @media (max-width: 1200px) {
      font-size: 0.7rem;
    }
  }
}

.dropDownMenuItem {
  height: 2rem;
  width: 90%;
  margin-top: 1rem;

  p {
    font-size: 1rem;
    font-weight: bold;
    color: var(--MENU-COLOR);
    text-transform: uppercase;
    cursor: pointer;
  }
}

.menuButtonBox {
  margin-left: 1rem;
}

.menuDropdownButton {
  background-color: var(--BUTTON-COLOR);
  width: 100% !important;

  p {
    color: white;
  }
}

.dropDownMenuBox {
  position: absolute;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 6rem;
  z-index: 99;
  height: 18rem;
}

.rightButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.625rem;

  p {
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
    cursor: pointer;

    @media (max-width: 1400px) {
      font-size: 1.2rem;
    }

    @media (max-width: 1150px) {
      font-size: 1rem;
    }
  }

  @media (max-width: 1000px) {
    display: none;
  }
}

.dropDownButton {
  @media (max-width: 1000px) {
    width: 90%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin: 1rem;
  }

  p {
    font-size: 1rem;
    color: var(--PRIMARY-COLOR);
    font-weight: bold;
    cursor: pointer;
  }
}

.button {
  background-color: var(--PRIMARY-COLOR);
  margin-left: 3rem;

  @media (max-width: 1300px) {
    margin-left: 1rem;
  }

  p {
    color: white;

    @media (max-width: 1400px) {
      font-size: 1.2rem !important;
    }

    @media (max-width: 1150px) {
      font-size: 1rem !important;
    }

    @media (max-width: 600px) {
      font-size: 0.8rem !important;
    }
  }
}

.selected {
  p {
    color: var(--PRIMARY-COLOR);
  }
}
