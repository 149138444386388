.box {
  border: 2px solid var(--FORM-BORDER-COLOR);
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 1rem;
  flex: 1;
  font-size: 1.1rem;
  border-radius: 0.3125rem;
}
