.backgroundBox {
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: var(--GRAY-LIGHT);
  background-image: url("../../../assets/reactangleTilt1.svg");
  background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hiringbox {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  max-width: 85rem;
}

.twoSideBox {
  width: 100%;
  margin-top: 5rem;
}

.rightHeading {
  color: white;
  font-weight: bold;
  font-size: 3.125rem;

  @media (max-width: 600px) {
    font-size: 2.25rem;
    margin-top: 2rem;
  }
}

.rightBox {
  width: 60%;

  @media (max-width: 800px) {
    width: 80%;
  }
}

.subHeading {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  margin-top: 2rem;

  @media (max-width: 800px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
}
