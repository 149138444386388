.box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  flex-direction: column;

  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.imagebox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5rem;

  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 2rem;
  }

  &:nth-child(odd) {
    @media (max-width: 800px) {
      flex-direction: column-reverse;
    }
  }
}

.subBox {
  width: 48%;

  @media (max-width: 800px) {
    width: 90%;
    margin-top: 1rem;
  }
}

.subTextBox {
  width: 48%;
  padding-left: 1rem;

  @media (max-width: 800px) {
    width: 90%;
    padding-left: 0rem;
  }
}

.imageHeading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 1rem;

  @media (max-width: 1200px) {
    font-size: 2rem;
  }
}

.grayBox {
  width: 100%;
  flex: 1;
  height: 100%;
}

.imageText {
  font-size: 1.25rem;
  margin-top: 2rem;

  @media (max-width: 1200px) {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
}

.backgroundBox {
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: var(--GRAY-LIGHT);
  background-image: url("../../assets/reactangleTilt1.svg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rectangleContainer {
  width: 90%;
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1300px) {
    width: 95%;
  }

  @media (max-width: 1000px) {
    width: 90%;
  }
}

.featureBox {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.featureContainer {
  width: 30%;
  padding: 1rem;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
  border-radius: 0.625rem;
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;

  @media (max-width: 1300px) {
    width: 26%;
  }

  @media (max-width: 1000px) {
    width: 90%;
    margin-top: 2rem;
  }
}

.HowWeDoItContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  img {
    flex: 0;
  }
}

.featureRightBox {
  margin-left: 1rem;
  flex: 1;
}

.featureBoxHeading {
  font-size: 1.625rem;
  font-weight: bold;
  color: black;

  @media (max-width: 1300px) {
    font-size: 1.225rem;
  }

  @media (max-width: 1000px) {
    font-size: 1.625rem;
  }

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
}

.featureBoxHeading {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--GRAY-HEADING);

  @media (max-width: 1300px) {
    font-size: 1.225rem;
  }

  @media (max-width: 1000px) {
    font-size: 1.625rem;
  }

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
}

.featureBoxText {
  margin-top: 1rem;
  font-size: 1.125rem;

  @media (max-width: 1300px) {
    font-size: 0.9rem;
  }

  @media (max-width: 1000px) {
    font-size: 1.125rem;
  }

  @media (max-width: 600px) {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
}

.arrowBox {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 1rem;
}

.howWeDoItBox {
  width: 100%;
  display: flex;
  margin-top: 5rem;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
}

.leftImage {
  width: 50%;

  img {
    width: 100%;
    object-fit: contain;
  }

  @media (max-width: 1000px) {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.rightHeading {
  color: var(--BLUE-COLOR);
  font-weight: bold;
  font-size: 3.125rem;

  @media (max-width: 600px) {
    font-size: 2.25rem;
    margin-top: 2rem;
  }
}

.contactUsBox {
  width: 100%;
  display: flex;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
}

.readyBox {
  width: 70%;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: var(--PRIMARY-COLOR);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 100%;
  }
}

.readyContainer {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.readyHeading {
  font-size: 2rem;
  color: white;
  font-weight: bold;

  @media (max-width: 800px) {
    font-size: 1.5rem;
  }
}

.contactBox {
  width: 30%;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: var(--BLUE-COLOR);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 100%;
  }
}

.button {
  background-color: var(--PRIMARY-COLOR);

  margin-left: 3rem;

  @media (max-width: 1300px) {
    margin-left: 1rem;
  }

  p {
    color: white;

    @media (max-width: 1400px) {
      font-size: 1.2rem !important;
    }

    @media (max-width: 1150px) {
      font-size: 1rem !important;
    }

    @media (max-width: 600px) {
      font-size: 0.8rem !important;
    }
  }
}
