body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.Box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.otherContentBox {
  width: 90%;
}
