:root {
  --MENU-BORDER: #000000;
  --BUTTON-COLOR: #1a1718;

  --BACKGROUND-LINEAR-1: #efe7ff;
  --BACKGROUND-LINEAR-2: #fbfbfb;

  --BORDER-COLOR: #ababab;
  --FORM-BORDER-COLOR: #cacdcf;
  --PRIMARY-COLOR: #0e9657;
  --GRAY-COLOR: #7a8188;
  --GRAY-HEADING: #3d4144;
  --GRAY-LIGHT: #f2f3f4;
  --GRAY-FORM: #a4a7a9;
  --GRAY-FORM-BUTTON: #e4e6e7;
  --HEADING-COLOR: #252b42;
  --BLUE-COLOR: #322781;
  --FORM-BOX-COLOR: #f8f8f8;

  --PRICE-BACKGROUND: #e9ebe4;
}
